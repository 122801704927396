import React from "react"
import tw from "twin.macro"

const gradientText = ({ gradient, otherStyles, children }) => {
  return (
    <div
      css={[
        tw`md:text-[22px] text-[20px] font-black leading-[27px] text-transparent bg-clip-text`,
        gradient || tw`gradient-text-purple-to-red`,
        otherStyles,
      ]}
    >
      {children}
    </div>
  )
}

export default gradientText
