import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import { graphql } from "gatsby"
import BgImageSection from "@components/narcolepsy/BgImageSection"
import Section from "@components/section"
import PrimaryCta from "@components/PrimaryCta"
import GradientText from "@components/gradientText.js"
import { Heading2, sectionSm, footnote } from "@styles/ui"

// Assets
import sodiumChart from "@images/low-sodium/xywav-sodium-content-bar-graph.svg"
import xywavLogo from "@images/low-sodium/xywav-logo-alt-gradient.svg"

const LowerSodiumPage = ({ location, data }) => {
  return (
    <Layout location={location} exitModalColour="purple">
      <Seo
        title="Comparing XYWAV Sodium Content to Xyrem® and Lumryz TM | XYWAV®"
        description="Learn the sodium differences between XYWAV® (calcium, magnesium, potassium, and sodium oxybates) and other treatments like XYREM® (sodium oxybate) and LumryzTM (sodium oxybate). See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero
        eyebrow="SODIUM IN XYWAV"
        gradient="purple-red"
        jumpLinks={[
          { text: "How much sodium is in XYWAV?", link: "#low-sodium" },
          {
            text: "XYWAV vs. XYREM",
            link: "#findings",
          },
        ]}
        footnotes={
          <>
            <span tw="flex">
              <sup tw="top-[7px]">*</sup>XYWAV contains 131 mg of sodium at the
              maximum recommended nightly dose.
            </span>
          </>
        }
      >
        <span tw="block mb-4.5 xl:mb-6">
          XYWAV is the first and only low&#8209;sodium* oxybate
        </span>
      </StaticHero>

      {/* talk to your doctor */}
      <BgImageSection
        imageData={data}
        imageLeft
        widerImage
        mobileAspectRatio={"73%"}
        altText="A woman discussing her sodium intake and her narcolepsy symptoms"
        imageStyles={tw`2xl:(max-h-[320px] [img]:(!object-contain) after:(left-[40rem]))`}
      >
        <>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md) md:before:(mb-[25px]) xl:before:(mb-6)"></div>

          <h2
            css={[
              Heading2,
              tw`font-avenir font-black text-[20px] leading-[27px] lg:(text-[22px])`,
            ]}
          >
            If you’re considering an{" "}
            <span tw="whitespace-nowrap">oxybate&mdash;either</span> with
            stimulants or on its{" "}
            <span tw="whitespace-nowrap">own&mdash;talk</span> to your doctor
            about the <span tw="whitespace-nowrap">high-sodium</span> elephant
            in the room.
          </h2>
          <h2
            css={[
              Heading2,
              tw`font-avenir font-black text-[20px] leading-[27px] mt-[25px] lg:(text-[22px])`,
            ]}
          >
            Ask about XYWAV, the only{" "}
            <span tw="whitespace-nowrap">low-sodium</span> option.
          </h2>
        </>
      </BgImageSection>

      {/* Lower sodium scrollable chart */}
      <Section id="low-sodium" bgColour={tw`lilac-gradient-vertical`}>
        <Container>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md left-1/2 transform -translate-x-1/2) md:before:(mb-[25px]) xl:before:(mb-6)"></div>
          <h2
            css={[
              Heading2,
              tw`mb-[15px] text-left md:text-center xl:(mb-[25px] mx-auto max-w-[62rem])`,
            ]}
          >
            XYWAV has ~1509 mg less sodium per maximum recommended nightly dose
            than high-sodium oxybates
          </h2>
          <div tw="bg-white py-[20px] mt-[25px] w-fit mx-auto md:(mt-10 rounded-b-3xl rounded-tr-[200px] py-[20px] pl-[35px] pr-[55px])">
            <img
              src={sodiumChart}
              tw="mt-0.5 mx-auto"
              alt="A bar graph comparing the sodium content of 9-g dose of XYREM/LUMRYZ (~1640 mg), 9-g dose of XYWAV (131 mg), and 4 large orders of fries (~1520 mg)"
            />
          </div>

          <div tw="md:(max-w-[80%] xl:(max-w-[57%]) mx-auto) text-left">
            <p css={[footnote, tw`mb-[2px] !text-[12px]`]}>
              <sup tw="-ml-2">&dagger;</sup>XYREM and LUMRYZ contain ~1,640 mg
              of sodium at the maximum recommended 9-g nightly dose.
            </p>
            <p css={[footnote, tw`mb-[2px] !text-[12px]`]}>
              <sup tw="-ml-2">&Dagger;</sup>Based on an average of 380 mg of
              sodium in 1 large serving of French fries according to a 2012 USDA
              analysis of 3 fast-food chains.
            </p>
            <p css={[footnote, tw`mb-[2px] !text-[12px]`]}>
              <sup tw="-ml-2">&sect;</sup>XYWAV contains 131 mg of sodium at the
              maximum recommended 9-g nightly dose.
            </p>
          </div>

          <div tw="lg:w-[80%] mx-auto my-[25px]">
            <p tw="text-[20px] leading-[27px] text-left font-black lg:(text-[22px] text-center max-w-[67rem])">
              If you choose a high-sodium oxybate, such as XYREM
              <sup>&reg;</sup>&nbsp;(sodium oxybate) oral solution or LUMRYZ
              <sup>&trade;</sup>&nbsp;(sodium oxybate) extended-release for oral
              suspension, you may go over the ideal daily sodium intake
              recommended by the American Heart Association without eating or
              drinking anything.
            </p>
            <PrimaryCta
              url={"/narcolepsy/transitioning-from-xyrem/"}
              addedStyles={tw`block mt-8 mx-auto`}
            >
              Transitioning from XYREM
            </PrimaryCta>
          </div>
        </Container>
      </Section>

      {/* Only XYWAV */}
      <section
        id="findings"
        css={[sectionSm, tw`relative py-[15px] md:(pt-[50px] pb-[25px])`]}
      >
        <Container>
          <div tw="flex flex-col justify-center items-center md:gap-[15px] lg:(flex-row gap-[40px])">
            <div tw="relative grid px-8 pt-12 pb-20 mb-4 border-gradient-rounded max-w-[340px] mx-auto before:(w-[340px] h-[340px]) after:(w-[338px] h-[338px]) lg:(mx-0 max-w-[370px] before:(w-[370px] h-[370px]) after:(w-[368px] h-[368px]))">
              <div tw="w-[133px] justify-self-center lg:(w-[164px])">
                <img src={xywavLogo} tw="w-full" alt="" />
              </div>
              <p tw="text-3xl leading-[25px] font-black text-studio text-center mt-6 lg:(leading-[28px])">
                Only XYWAV is clinically superior to XYREM,
                a&nbsp;high&#8209;sodium oxybate, based on safety
                <sup tw="font-black bg-clip-text">&#8214;</sup>
              </p>
            </div>
            <div tw="flex flex-col gap-[15px] max-w-[780px]">
              <p tw="narc-purple-red-gradient text-white font-black text-base leading-[22px] py-[25px] px-[20px] rounded-[20px] lg:(px-[30px])">
                XYWAV has ~1509 mg less sodium per night than XYREM at the
                maximum 9&#8209;g nightly dose<sup>&para;</sup>
              </p>
              <p tw="narc-purple-red-gradient text-white font-black text-base leading-[22px] py-[25px] px-[20px] rounded-[20px] lg:(px-[30px])">
                Due to the reduction in sodium, the FDA determined XYWAV is
                clinically superior to XYREM by means of cardiovascular safety
                <sup>&#8214;</sup>
              </p>
              <p tw="narc-purple-red-gradient text-white font-black text-base leading-[22px] py-[25px] px-[20px] rounded-[20px] lg:(px-[30px])">
                According to the FDA the differences in sodium content between
                XYWAV and XYREM at recommended doses will be clinically
                meaningful in reducing cardiovascular diseases for many people
                with narcolepsy who suffer from cataplexy and/or EDS
                <sup>&#8214;</sup>
              </p>
            </div>
          </div>
        </Container>
      </section>

      {/* Choosing XYWAV */}
      <section tw="py-[15px] lg:py-[25px]">
        <Container>
          <div tw="before:(gradientRed block relative w-[124px] mb-5 h-1.5 rounded-md left-1/2 transform -translate-x-1/2) md:before:(mb-6)"></div>
          <GradientText
            otherStyles={tw`mb-[15px] text-center lg:(mb-6 w-[70%] mx-auto)`}
          >
            Choosing XYWAV over high&#8209;sodium oxybates, including the
            once&#8209;nightly high&#8209;sodium oxybate, reduces chronic sodium
            burden, which makes it the safer option.
          </GradientText>
          <p tw="text-[20px] leading-[27px] text-left font-black mb-6 lg:(text-[22px] text-center)">
            A clinical trial comparing the efficacy and safety of XYWAV and
            LUMRYZ has not been conducted.
          </p>
          <p tw="text-left text-[12px] leading-[17px]">
            <sup>&#8214;</sup>The decision of the FDA Office of Orphan Product
            Development (OOPD) is based on findings that XYWAV provides a
            greatly reduced chronic sodium burden compared to XYREM. There are
            no head-to-head data for XYWAV and XYREM.
          </p>
          <p tw="text-left text-[12px] leading-[17px]">
            <sup>&para;</sup>XYWAV has 131&nbsp;mg of sodium per maximum
            recommended 9-g nightly dose, while the equivalent dose of
            high-sodium oxybate XYREM contains ~1640&nbsp;mg—a difference of
            ~1509&nbsp;mg every single day.
          </p>
        </Container>
      </section>

      <section tw="py-[25px] bg-lilac">
        <Container>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md left-1/2 transform -translate-x-1/2) md:before:(mb-[25px]) xl:before:(mb-6)"></div>
          <h2 css={[Heading2, tw`mb-[15px] xl:mb-[25px] text-center`]}>
            Wondering if an oxybate treatment like low-sodium XYWAV might be
            right for you?
          </h2>
          <div tw="flex justify-center">
            <PrimaryCta
              url={"/narcolepsy/talk-to-your-doctor/#quiz"}
              addedStyles={tw`md:max-w-[12rem]`}
            >
              Take the Quiz
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default LowerSodiumPage

export const query = graphql`
  query {
    bgImageSection: file(
      relativePath: { eq: "low-sodium/woman-with-doctor-sodium-elephant.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: {
        eq: "low-sodium/woman-with-doctor-sodium-elephant-mobile.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
